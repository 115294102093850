import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { getDatabase, ref, push } from "firebase/database";
import { PhotoCamera, Delete } from "@mui/icons-material";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

const NovaNoticia = () => {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [fonte, setFonte] = useState('');
  const [autor, setAutor] = useState('');
  const [data, setData] = useState('');
  const [imagens, setImagens] = useState([]);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (imagens.length + files.length <= 5) {
      setImagens(prevImages => [...prevImages, ...files]);
    } else {
      alert('Você pode selecionar no máximo 5 imagens.');
    }
  };

  const removeImage = (index) => {
    setImagens(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('title', titulo);

    const response = await fetch(`${KEY_SITE}upload_image.php`, {
      method: 'POST',
      body: formData,
    });

    const result = await response.text();
    if (!response.ok) {
      throw new Error(result || 'Erro ao enviar a imagem');
    }

    return result;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (imagens.length === 0) {
        alert('Por favor, selecione pelo menos uma imagem antes de salvar.');
        return;
      }

      const uploadedImages = {};
      for (let i = 0; i < imagens.length; i++) {
        const imageKey = i === 0 ? 'imagem' : `imagem${i}`;
        uploadedImages[imageKey] = await uploadImage(imagens[i]);
      }

      const noticia = {
        titulo,
        descricao,
        fonte,
        autor,
        data,
        ...uploadedImages,
      };

      const newsRef = ref(database, `db/cidades/${KEY}/noticias/`);
      await push(newsRef, noticia);

      navigate(`/${KEY}/noticias`);
    } catch (error) {
      console.error('Erro ao salvar a Notícia:', error);
      alert('Erro ao salvar a Notícia');
    }
  };

  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
      <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
        CADASTRAR NOVA NOTICIA
      </Divider>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              multiline
              rows={10}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Fonte"
              value={fonte}
              onChange={(e) => setFonte(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Autor"
              value={autor}
              onChange={(e) => setAutor(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Data"
              type="date"
              value={data}
              onChange={(e) => setData(e.target.value)}
              InputLabelProps={{ shrink: true, style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={6}>

            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="icon-button-file"
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera style={{ color: "#0F71FD", fontSize: '30px' }} />
                <Typography variant="h6" color="#FFF" marginLeft={1}> Selecione até 5 Imagens</Typography>
              </IconButton>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="#FFF" marginLeft={1}> Imagens Selecionadas:</Typography>
            <List>
              {imagens.map((imagem, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeImage(index)}>
                      <Delete style={{ color: "#0F71FD", fontSize: '30px' }} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={imagem.name} style={{ color: "#FFF" }} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, bgcolor: "#0F71FD" }}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NovaNoticia;
