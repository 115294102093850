import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import firebaseApp from '../../integration/FirebaseConfig';

const Manutencao = () => {

  const navigate = useNavigate();

  const auth = getAuth(firebaseApp);


  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/login");
      window.location.reload();
    }).catch((error) => {

      console.error("Erro ao fazer logout:", error);
    });

  };


  const reloadPage = () => {
    window.location.reload();
  };


  return (
    <>
      <Helmet>
        <title>Painel DHF - Controle de Acesso</title>
      </Helmet>

      <div className="login-container">
        <div className="logo-container">
          <img src="/images/logobs.png" alt="Logo" width="300" />
        </div>
        <div className="login-form" style={{
          backgroundColor: "#333333",
          border: "2px solid #0F71FD", borderRadius: 18, paddingTop: 20, paddingBottom: 20
        }}>
          <h1>Manutenção Programada</h1>
          <Box m="20px" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>Prezado usuário,</Typography>

            <Typography style={{ textAlign: 'justify', maxWidth: 500 }}>Nosso sistema está passando por uma breve manutenção, Não se preocupe, isso é parte de nossos esforços contínuos para melhorar e garantir a estabilidade do sistema. A manutenção geralmente dura apenas alguns minutos e logo estaremos de volta com uma experiência ainda melhor para você. Agradecemos sua compreensão e paciência.</Typography>

            <Typography style={{ marginTop: 10 }}>Atenciosamente,</Typography>
            <Typography>Equipe de desenvolvimento da BIGSTAR SOFTWARES</Typography>
            <Button onClick={handleLogout} variant="contained" sx={{ marginTop: '20px', backgroundColor: '#0F71FD', color: 'white', fontWeight: 'bold' }}>DESLOGAR</Button>
            <Button onClick={reloadPage} variant="contained" sx={{
              marginTop: '20px', backgroundColor: "transparent",
              border: "1px solid #0F71FD", color: 'white', fontWeight: 'bold'
            }}>ATUALIZAR</Button>

          </Box>

        </div>

      </div>

    </>



  );
};

export default Manutencao;
