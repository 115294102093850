import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import MenuRouter from "./MenuRouter";
import firebaseApp, { DATA_LOGIN, KEY, KEY_CATEGORIA, KEY_LICENSE, KEY_MASTER, KEY_NOME, NAME } from "../integration/FirebaseConfig";
import {
  Box, Typography, IconButton, useTheme, Avatar, Skeleton, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Button, Select, InputLabel, FormControl, ListItem, RadioGroup,
  FormControlLabel,
  Radio, List, ListItemText, ListItemAvatar, ListItemIcon, Collapse,
  ListItemButton
} from "@mui/material";
import '../index.css'
import { getAuth } from 'firebase/auth';
import { getDatabase, off, onValue, ref, update } from "firebase/database";
import KeyIcon from '@mui/icons-material/Key';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Switch from '@mui/material/Switch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import GavelIcon from '@mui/icons-material/Gavel';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';


const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      icon={icon}
      onClick={() => {
        setSelected(title);
        setIsCollapsed(true);
      }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ onToggle }) => {
  const database = getDatabase(firebaseApp);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [showLicense, setShowLicense] = useState(false);
  const [open, setOpen] = React.useState(false);
  const auth = getAuth();

  const keyCategoriaString = KEY.toString();


  const handleToggleLicense = () => {
    setShowLicense(prevShowLicense => !prevShowLicense);
  };

  const handleClick = () => {
    setOpen(!open);
  };


  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };


  useEffect(() => {
    const manutencaoRef = ref(database, `db/cidades/${KEY}/manutencao/ativo`);

    const onManutencaoValue = (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {

        setManutencaoAtiva(data);
      }
    };

    onValue(manutencaoRef, onManutencaoValue);

    return () => {
      off(manutencaoRef, onManutencaoValue);
    };
  }, []);

  const handleManutencaoChange = (event) => {
    const newValue = event.target.checked;

    update(ref(database, `db/cidades/${KEY}/manutencao`), {
      ativo: newValue
    }).then(() => {
      console.log("Manutenção atualizada com sucesso");
    }).catch((error) => {
      console.error('Erro ao atualizar a manutenção:', error);
    });
  };

  useEffect(() => {
    onToggle(isCollapsed);
  }, [isCollapsed, onToggle]);

  const handleToggle = () => {
    setIsCollapsed(prev => !prev);
  };

  useEffect(() => {
    const database = getDatabase();
    const cidadesRef = ref(database, 'codigo_cidade');

    onValue(cidadesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const citiesArray = Object.entries(data)
          .map(([key, value]) => ({
            id: key,
            codigo: value.codigo,
            nome: value.nome,
            ativo: value.ativo,
            bloqueado: value.bloqueado,
            site: value.site
          }))
          .filter(city => city.ativo === true);
        setCidades(citiesArray);
      }
    });
  }, []);

  const handleConfirmCityChange = () => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const selectedCityData = cidades.find(city => String(city.codigo) === String(selectedCity));

    if (selectedCity == KEY) {
      handleDialogClose();
      return;
    }

    console.log('UserID:', userId);
    console.log('Selected City:', selectedCity);
    console.log('Selected City Data:', selectedCityData);

    if (userId && selectedCityData) {
      const cityCode = Number(selectedCityData.codigo);
      if (!isNaN(cityCode)) {
        update(ref(database, `usuarios/${userId}`), {
          cidade: cityCode,
          site: selectedCityData.site,
          KEY_NAME: selectedCityData.nome
        })
          .then(() => {
            console.log("Cidade e site atualizados com sucesso");
            handleDialogClose();
            window.location.reload();
          })
          .catch((error) => {
            console.error("Erro ao atualizar a cidade e site:", error);
          });
      } else {
        console.error('City code is not a valid number:', selectedCityData.codigo);
      }
    } else {
      console.log('No city data found or user not authenticated');
    }
  };


  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };



  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        zIndex="999"
        height="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: isCollapsed ? "80px" : "250px",
          maxWidth: isCollapsed ? "80px" : "250px",
          transition: "min-width 3s ease, max-width 3s ease",
        }}
      >
        <ProSidebar collapsed={isCollapsed} className="custom-scroll" style={{ transition: 'margin-left 0.3s ease, width 0.3s ease' }}>
          <Menu iconShape="square">
            <MenuItem
              onClick={handleToggle}
              icon={isCollapsed ? <img src="/images/bs.png" style={{ maxWidth: 37 }} />
                : undefined}
              style={{
                margin: "10px 0 15px",
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <img
                    src="/images/logobs.png"
                    alt="logo"
                    style={{
                      width: "155px",
                    }}
                  />
                  <IconButton>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed ? (
              <>
                <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'transparent' }}>
                  <ListItem button onClick={handleToggleLicense}>
                    <ListItemAvatar>
                      <Avatar>
                        <CodeOffIcon />
                      </Avatar>
                    </ListItemAvatar>
                    {showLicense ? (
                      <>
                        {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER ? (<>

                          <ListItemText primary="LICENÇA PRO" secondary={`${KEY_LICENSE.toLocaleUpperCase()}`} />
                        </>) : (<>
                          <ListItemText primary="LICENÇA BASIC" secondary={`${KEY_LICENSE.toLocaleUpperCase()}`} />

                        </>)}



                        <IconButton>
                          <VisibilityOffIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER ? (<>

                          <img
                            src="/images/license_pro.png"
                            alt={`${KEY_LICENSE.toLocaleUpperCase()}`}
                            style={{
                              width: "115px",
                            }}
                          />
                        </>) : (<>
                          <img
                            src="/images/license_basic.png"
                            alt={`${KEY_LICENSE.toLocaleUpperCase()}`}
                            style={{
                              width: "115px",
                            }}
                          />

                        </>)}

                      </>
                    )}
                  </ListItem>


                  <ListItem button onClick={handleClick}>
                    <ListItemAvatar>
                      <Avatar>
                        <DeveloperModeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="OPERACIONAL" secondary='INFORMAÇÕES' sx={{ color: 'white' }} />
                    {open ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />}
                  </ListItem>


                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <SupervisedUserCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${NAME.toLocaleUpperCase()}`} secondary={`${DATA_LOGIN.toLocaleUpperCase()}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <WorkIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='CIDADE DE ACESSO' secondary={`${KEY_NOME.toLocaleUpperCase()}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <VerifiedUserIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="TIPO DE ACESSO" secondary={`${KEY_CATEGORIA.toLocaleUpperCase()}`} />
                      </ListItem>

                      {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <EngineeringIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="MANUTENÇÃO" secondary='ATIVAR AVISO ' />
                            <Switch color="success" onChange={handleManutencaoChange} checked={manutencaoAtiva} />
                          </ListItem>
                          {KEY_MASTER === true && (<>
                            <ListItem button onClick={handleDialogOpen}>
                              <ListItemAvatar>
                                <Avatar>
                                  <KeyIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary='TROCAR CIDADES' secondary='ALT. CHAVE DE ACESSO' />
                            </ListItem>

                          </>)}

                        </>
                      )}

                      {KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER && (
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <EngineeringIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="MANUTENÇÃO" secondary='ATIVAR AVISO ' />
                            <Switch color="success" onChange={handleManutencaoChange} checked={manutencaoAtiva} />
                          </ListItem>
                          {KEY_MASTER === true && (<>
                            <ListItem button onClick={handleDialogOpen}>
                              <ListItemAvatar>
                                <Avatar>
                                  <KeyIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary='TROCAR CIDADES' secondary='ALT. CHAVE DE ACESSO' />
                            </ListItem>

                          </>)}

                        </>
                      )}
                    </List>
                  </Collapse>
                </List>
              </>
            ) : (
              <>

                <List sx={{ width: '100%', maxWidth: '100%', height: '100%', bgcolor: 'transparent' }}  >
                  <ListItem onClick={handleToggle}>
                    <ListItemAvatar>
                      <Avatar>
                        <CodeOffIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <DeveloperModeIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItem>
                  {open && (<>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <SupervisedUserCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <VerifiedUserIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                    {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (
                      <>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <EngineeringIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </ListItem>
                        <ListItem onClick={handleDialogOpen}>
                          <ListItemAvatar>
                            <Avatar>
                              <KeyIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </ListItem>
                      </>
                    )}
                  </>
                  )}
                </List></>)}



            <Box paddingLeft={!isCollapsed ? "10%" : undefined}>
              <MenuRouter selected={selected} isCollapseds={isCollapsed} collapseSidebar={setIsCollapsed} />
            </Box>


          </Menu>
        </ProSidebar>
      </Box>




      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Selecione um Acesso</DialogTitle>
        <DialogContent>
          <RadioGroup value={selectedCity} color="success" onChange={handleCityChange} >
            {cidades.map((cidade) => (
              <FormControlLabel
                key={cidade.codigo}
                value={cidade.codigo}
                control={<Radio sx={{
                  color: '#0F71FD',
                  '&.Mui-checked': {
                    color: '#0F71FD',
                  },
                }} />}
                label={`${cidade.nome} - ${cidade.codigo}`}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{
            backgroundColor: "transparent",
            border: "1px solid #0F71FD",
            borderRadius: "4px",
            padding: "8px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmCityChange} style={{ backgroundColor: "#0F71FD", fontWeight: "bold", marginRight: "10px" }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sidebar;
