import { useEffect, useState } from 'react';
import { ref, get, set, getDatabase, onValue } from 'firebase/database';
import { getAuth, getIdToken, signOut } from 'firebase/auth';
import { format } from 'date-fns';
import firebaseApp from '../integration/FirebaseConfig';

export function useLastVersion() {
  const [lastVersionNumber, setLastVersionNumber] = useState(null);

  useEffect(() => {
    const database = getDatabase(firebaseApp);
    const versionsRef = ref(database, 'panel/versoes/atual');

    const unsubscribe = onValue(versionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.versionNumber) {
        setLastVersionNumber(data.versionNumber);
      }
    });
    return () => unsubscribe();
  }, []);

  return lastVersionNumber;
}

export const checkTokenValidity = async (database, auth, userId, generatedToken) => {
  const userTokenRef = ref(database, `usuarios/${userId}/token`);
  const acessoDuplo = ref(database, `usuarios/${userId}/ativo`);
  console.log('aqui')
  try {
    get(userTokenRef)
      .then((snapshot) => {
        const savedToken = snapshot.val();
        console.log(savedToken)
        if (savedToken === generatedToken) {
          console.log('DHF - Token Válido.');

        } else if (savedToken === '0') {
          reloadPage()


        } else {
          get(acessoDuplo)
            .then((snapshot) => {
              const dualAcess = snapshot.val();
              if (dualAcess === true) {

              } else {
                signOut(auth)
                  .then(() => {
                    console.log('Usuário desconectado.');
                    reloadPage();
                  })
                  .catch((error) => {
                    console.error('Erro ao fazer logout:', error);
                  });
              }
            })
        }
      })
      .catch((error) => {
        console.error('Erro ao verificar a validade do token:', error);
      });
  } catch (error) {
    console.error('Erro ao verificar a validade do token:', error);
  }
};

export const checkToken = async (database, auth, userId) => {
  const userTokenRef = ref(database, `usuarios/${userId}/token`);
  const ativo = ref(database, `usuarios/${userId}/ativo`);
  try {
    get(userTokenRef)
      .then(async (snapshot) => {
        const savedToken = snapshot.val();
        if (savedToken === '0') {
          console.log('AUTH - Token Inválido.');
          await signOut(auth);
          console.log('Usuário desconectado.');
          reloadPage();
        } else {
          const idToken = await getIdToken(auth.currentUser);

          if (idToken !== savedToken) {
            console.log('AUTH - Token da Sessão não corresponde ao Token Armazenado.');
            await signOut(auth);
            console.log('Usuário desconectado.');

          } else {
            console.log('Tokens correspondem. Usuário autenticado.');
            get(ativo)
            .then((snapshot) => {
              const dualAcess = snapshot.val();
              if (dualAcess === true) {

              } else {
                signOut(auth)
                  .then(() => {
                    console.log('Usuário desconectado.');
                    reloadPage();
                  })
                  .catch((error) => {
                    console.error('Erro ao fazer logout:', error);
                  });
              }
            })
          }
        }
      })
      .catch((error) => {
        console.error('Erro ao verificar a validade do token:', error);
      });
  } catch (error) {
    console.error('Erro ao verificar a validade do token:', error);
  }
};

export const generateAndSaveToken = async (database, userId, Token) => {
  const token = generateToken()
  const dataHoraAtual = new Date().toLocaleString();

  try {
    const userTokenRef = ref(database, `usuarios/${userId}/token`);
    const userLogin = ref(database, `usuarios/${userId}/horaLogin`);

    await Promise.all([
      set(userTokenRef, token),
      set(userLogin, dataHoraAtual)
    ]);


    return token;
  } catch (error) {
    console.error('Erro ao salvar token no Firebase:', error);
    return null;
  }
};

export const reloadPage = () => {
  window.location.reload();
};


export const generateToken = (value) => {
  const tokenLength = value;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < tokenLength; i++) {
    if (i > 0 && i % 5 === 0) {
      token += '-';
    }
    token += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return token;
};


export function formatarTelefone(telefone) {

  const numerosTelefone = telefone.replace(/\D/g, '');

  if (numerosTelefone.length === 12) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;
  } else if (numerosTelefone.length === 11) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 3)}) ${numerosTelefone.slice(3, 7)}-${numerosTelefone.slice(7)}`;
  } else if (numerosTelefone.length === 10) {
    return `+${numerosTelefone.slice(0, 2)} (${numerosTelefone.slice(2, 4)}) ${numerosTelefone.slice(4, 8)}-${numerosTelefone.slice(8)}`;
  }
}

export function formatUTCDateToUTCPlusThree(dateString) {
  const selectedEventDate = new Date(dateString);
  selectedEventDate.setUTCHours(selectedEventDate.getUTCHours() + 6);
  const formattedDate = format(selectedEventDate, '(dd/MM/yyyy)');
  return formattedDate;
}

export const categoryColors = {
  azul: 'blue',
  verde: 'green',
  amarelo: 'yellow',
  laranja: '#e86c0d',
  vermelho: 'red',
};

export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const handleCopyClick = (userId) => {
  navigator.clipboard.writeText(userId)
    .then(() => console.log('Copiado'))
    .catch((error) => console.error('Erro ao copiar', error));
};

export const isCPF = (term) => {
  const cpfRegex = /^\d{3}\d{3}\d{3}\d{2}$/;
  return cpfRegex.test(term);
};

export const isEmail = (term) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(term);
};

export const isData = (term) => {

  return term.includes('/')
};

export const formatarId = (id) => {
  return `+${id.slice(0, 2)} (${id.slice(2, 4)}) ${id.slice(4, 8)}-${id.slice(8)}`;
};


export function cpfIsValid(cpf) {
  cpf = String(cpf).replace(/\D/g, '');
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let remainder;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = 11 - (sum % 11);
  remainder = remainder >= 10 ? 0 : remainder;

  return remainder === parseInt(cpf.charAt(10));
}

export const allSpecialties = [
  "",
  "Angiologia",
  "Cardiologia",
  "Cirurgia Geral",
  "Cirurgia Pediátrica",
  "Cirurgia Plástica",
  "Citologia",
  "Clínica Médica",
  "Dermatologia",
  "Eletrocardiografia",
  "Eletroencefalografia",
  "Endocrinologia",
  "Endoscopia",
  "Fisioterapia",
  "Fonoaudiologia",
  "Gastroenterologia",
  "Genética",
  "Geriatria",
  "Ginecologia",
  "Hematologia",
  "Hemodinâmica",
  "Hemoterapia",
  "Homeopatia",
  "Imaginologia",
  "Imunologia",
  "Medicina Nuclear",
  "Nefrologia",
  "Neurocirurgia",
  "Neurologia",
  "Nutrição",
  "Obstetrícia",
  "Odontologia",
  "Oftalmologia",
  "Oncologia",
  "Ortopedia",
  "Otorrinolaringologia",
  "Pediatria",
  "Psicologia",
  "Psicoterapia",
  "Pneumologia",
  "Proctologia",
  "Psiquiatria",
  "Quimioterapia",
  "Radiologia",
  "Radioterapia",
  "Reumatologia",
  "Ressonância Magnética",
  "Tomografia",
  "Traumatologia",
  "Ultra-sonografia",
  "Urologia",
];

export function gerarID() {
  const numeroAleatorio = Math.floor(10000000 + Math.random() * 90000000);
  return numeroAleatorio.toString();
}

export const formatarData = (data) => {
  const dataObj = new Date(data);
  const dia = String(dataObj.getDate()).padStart(2, '0');
  const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
  const ano = dataObj.getFullYear();
  return `${dia}/${mes}/${ano}`;
};

export const priorities = [
  { value: 'amarelo', label: 'Prioridade (Idoso)' },
  { value: 'laranja', label: 'Prioridade (Grávida)' },
  { value: 'vermelho', label: 'Prioridade (Emergência)' },
  { value: 'azul', label: 'Prioridade (Portador de deficiência)' },
];


export const cidadesPB = [
  "Água Branca",
  "Aguiar",
  "Alagoa Grande",
  "Alagoa Nova",
  "Alagoinha",
  "Alcantil",
  "Algodão de Jandaíra",
  "Alhandra",
  "Amparo",
  "Aparecida",
  "Araçagi",
  "Arara",
  "Araruna",
  "Areia",
  "Areia de Baraúnas",
  "Areial",
  "Aroeiras",
  "Assunção",
  "Baía da Traição",
  "Bananeiras",
  "Baraúna",
  "Barra de Santa Rosa",
  "Barra de Santana",
  "Barra de São Miguel",
  "Bayeux",
  "Belém",
  "Belém do Brejo do Cruz",
  "Bernardino Batista",
  "Boa Ventura",
  "Boa Vista",
  "Bom Jesus",
  "Bom Sucesso",
  "Bonito de Santa Fé",
  "Boqueirão",
  "Borborema",
  "Brejo do Cruz",
  "Brejo dos Santos",
  "Caaporã",
  "Cabaceiras",
  "Cabedelo",
  "Cachoeira dos Índios",
  "Cacimba de Areia",
  "Cacimba de Dentro",
  "Cacimbas",
  "Caiçara",
  "Cajazeiras",
  "Cajazeirinhas",
  "Caldas Brandão",
  "Camalaú",
  "Campina Grande",
  "Campo de Santana",
  "Capim",
  "Caraúbas",
  "Carrapateira",
  "Casserengue",
  "Catingueira",
  "Catolé do Rocha",
  "Caturité",
  "Conceição",
  "Condado",
  "Conde",
  "Congo",
  "Coremas",
  "Coxixola",
  "Cruz do Espírito Santo",
  "Cubati",
  "Cuité",
  "Cuité de Mamanguape",
  "Cuitegi",
  "Curral de Cima",
  "Curral Velho",
  "Damião",
  "Desterro",
  "Diamante",
  "Dona Inês",
  "Duas Estradas",
  "Emas",
  "Esperança",
  "Fagundes",
  "Frei Martinho",
  "Gado Bravo",
  "Guarabira",
  "Gurinhém",
  "Gurjão",
  "Ibiara",
  "Igaracy",
  "Imaculada",
  "Ingá",
  "Itabaiana",
  "Itaporanga",
  "Itapororoca",
  "Itatuba",
  "Jacaraú",
  "Jericó",
  "João Pessoa",
  "Juarez Távora",
  "Juazeirinho",
  "Junco do Seridó",
  "Juripiranga",
  "Juru",
  "Lagoa",
  "Lagoa de Dentro",
  "Lagoa Seca",
  "Lastro",
  "Livramento",
  "Logradouro",
  "Lucena",
  "Mãe d'Água",
  "Malta",
  "Mamanguape",
  "Manaíra",
  "Marcação",
  "Mari",
  "Marizópolis",
  "Massaranduba",
  "Mataraca",
  "Matinhas",
  "Mato Grosso",
  "Maturéia",
  "Mogeiro",
  "Montadas",
  "Monte Horebe",
  "Monteiro",
  "Mulungu",
  "Natuba",
  "Nazarezinho",
  "Nova Floresta",
  "Nova Olinda",
  "Nova Palmeira",
  "Olho d'Água",
  "Olivedos",
  "Ouro Velho",
  "Parari",
  "Passagem",
  "Patos",
  "Paulista",
  "Pedra Branca",
  "Pedra Lavrada",
  "Pedras de Fogo",
  "Pedro Régis",
  "Piancó",
  "Picuí",
  "Pilar",
  "Pilões",
  "Pilõezinhos",
  "Pirpirituba",
  "Pitimbu",
  "Pocinhos",
  "Poço Dantas",
  "Poço de José de Moura",
  "Pombal",
  "Prata",
  "Princesa Isabel",
  "Puxinanã",
  "Queimadas",
  "Quixabá",
  "Remígio",
  "Riachão",
  "Riachão do Bacamarte",
  "Riachão do Poço",
  "Riacho de Santo Antônio",
  "Riacho dos Cavalos",
  "Rio Tinto",
  "Salgadinho",
  "Salgado de São Félix",
  "Santa Cecília",
  "Santa Cruz",
  "Santa Helena",
  "Santa Inês",
  "Santa Luzia",
  "Santa Rita",
  "Santa Teresinha",
  "Santana de Mangueira",
  "Santana dos Garrotes",
  "Santarém",
  "Santo André",
  "São Bentinho",
  "São Bento",
  "São Domingos de Pombal",
  "São Domingos do Cariri",
  "São Francisco",
  "São João do Cariri",
  "São João do Rio do Peixe",
  "São João do Tigre",
  "São José da Lagoa Tapada",
  "São José de Caiana",
  "São José de Espinharas",
  "São José de Piranhas",
  "São José de Princesa",
  "São José do Bonfim",
  "São José do Brejo do Cruz",
  "São José do Sabugi",
  "São José dos Cordeiros",
  "São José dos Ramos",
  "São Mamede",
  "São Miguel de Taipu",
  "São Sebastião de Lagoa de Roça",
  "São Sebastião do Umbuzeiro",
  "Sapé",
  "Seridó",
  "Serra Branca",
  "Serra da Raiz",
  "Serra Grande",
  "Serra Redonda",
  "Serraria",
  "Sertãozinho",
  "Sobrado",
  "Solânea",
  "Soledade",
  "Sossêgo",
  "Sousa",
  "Sumé",
  "Taperoá",
  "Tavares",
  "Teixeira",
  "Tenório",
  "Triunfo",
  "Uiraúna",
  "Umbuzeiro",
  "Várzea",
  "Vieirópolis",
  "Vista Serrana",
  "Zabelê"
]

export const PB = [
  "Paraiba"
]