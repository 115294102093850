import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { getDatabase, ref, push, update } from "firebase/database";
import { PhotoCamera, Delete } from "@mui/icons-material";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

const EditVereador = () => {
  const location = useLocation();
  const { userData } = location.state;

  const [nome, setNome] = useState(userData.nome || '');
  const [descricao, setDescricao] = useState(userData.descricao || '');
  const [partido, setPartido] = useState(userData.partido || '');

  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const noticia = {
        nome,
        descricao,
        partido,
      };

      const newsRef = ref(database, `db/cidades/${KEY}/vereadores/${userData.id}`);
      await update(newsRef, noticia);

      navigate(`/${KEY}/vereadores`);
    } catch (error) {
      console.error('Erro ao salvar a Notícia:', error);
      alert('Erro ao salvar a Notícia');
    }
  };

  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
      <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
        EDITAR VEREADOR
      </Divider>
      <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              multiline
              rows={10}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Partido"
              value={partido}
              onChange={(e) => setPartido(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>


          
        
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, bgcolor: "#0F71FD" }}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditVereador;
