import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { getDatabase, ref, push, update } from "firebase/database";
import { PhotoCamera, Delete } from "@mui/icons-material";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

const EditNoticia = () => {
  const location = useLocation();
  const { userData } = location.state;
  const [titulo, setTitulo] = useState(userData.titulo || '');
  const [descricao, setDescricao] = useState(userData.descricao || '');
  const [fonte, setFonte] = useState(userData.fonte || '');
  const [autor, setAutor] = useState(userData.autor || '');
  const [data, setData] = useState(userData.data || '');
  const [imagens, setImagens] = useState([]);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (imagens.length + files.length <= 5) {
      setImagens(prevImages => [...prevImages, ...files]);
    } else {
      alert('Você pode selecionar no máximo 5 imagens.');
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const noticia = {
        titulo,
        descricao,
        fonte,
        autor,
        data,
      };

      const newsRef = ref(database, `db/cidades/${KEY}/noticias/${userData.id}`);
      await update(newsRef, noticia);

      navigate(`/${KEY}/noticias`);
    } catch (error) {
      console.error('Erro ao salvar a Notícia:', error);
      alert('Erro ao salvar a Notícia');
    }
  };

  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
      <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
        EDITAR NOTICIA
      </Divider>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Descrição"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              multiline
              rows={10}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Fonte"
              value={fonte}
              onChange={(e) => setFonte(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Autor"
              value={autor}
              onChange={(e) => setAutor(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Data"
              type="date"
              value={data}
              onChange={(e) => setData(e.target.value)}
              InputLabelProps={{ shrink: true, style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
      
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, bgcolor: "#0F71FD" }}>
              Salvar Edição
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditNoticia;
