import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getDatabase, ref, onValue, remove } from '@firebase/database';
import firebaseApp, { KEY } from '../../integration/FirebaseConfig';
import { Helmet } from "react-helmet";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AddBoxIcon from '@mui/icons-material/AddBox';

const database = getDatabase(firebaseApp);

const Noticias = () => {
  const [userData, setUserData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();

  const columns = [
    {
      field: "titulo",
      headerName: "Titulo",
      flex: 3,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "data",
      headerName: "Data",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClick(row)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon /> EXCLUIR
          </IconButton>
        </Box>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "titulo",
      headerName: "Titulo",
      flex: 1,
      fontWeight: 'bold',
      cellClassName: "name-column--cell",
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClick(row)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
          >
            <DeleteOutlineIcon /> EXCLUIR
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    try {
      const usersRef = ref(database, `db/cidades/${KEY}/noticias`);
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dados = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));


          dados.sort((a, b) => new Date(b.data) - new Date(a.data));

          setUserData(dados);
        } else {
          setUserData([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, []);


  const handleAdd = (user) => {
    navigate(`/${KEY}/noticias/editar`, { state: { userData: user } });
  };

  const handleDeleteClick = (news) => {
    setSelectedNews(news);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedNews) {
      const newsRef = ref(database, `db/cidades/${KEY}/noticias/${selectedNews.id}`);
      remove(newsRef)
        .then(() => {
          setOpenDialog(false);
          setSelectedNews(null);
        })
        .catch((error) => {
          console.error('Erro ao excluir a notícia:', error);
        });
    }
  };

  return (
    <>

      <Helmet>
        <title>Painel - Noticias</title>
      </Helmet>

      <Box m="20px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/bs.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          {!isMobile ? (<> <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            NOTICIAS
          </Typography></>) : (<> <Typography
            variant="h4"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            NOTICIAS
          </Typography></>)}


          <Link to={`/${KEY}/noticias/novo`}>
            <IconButton variant="contained" style={{ backgroundColor: "#0F71FD", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }}>
              <AddBoxIcon style={{ marginRight: 4 }} /> NOVO
            </IconButton>
          </Link>



        </Box>
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columns}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        ) : (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columnsMobile}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )}
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir esta notícia?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              color: 'white',
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            style={{
              backgroundColor: "#0F71FD",
              fontWeight: 'bold',
              color: 'white',
            }}
            color="error"
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Noticias;
