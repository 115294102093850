import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getDatabase, ref, onValue, remove } from '@firebase/database';
import firebaseApp, { KEY, KEY_CATEGORIA } from '../../integration/FirebaseConfig';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Helmet } from "react-helmet";
import AddBoxIcon from '@mui/icons-material/AddBox';

const database = getDatabase(firebaseApp);

const Documentos = () => {
  const [userData, setUserData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [documentType, setDocumentType] = useState('processoseletivo');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 3,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },
    {
      field: "mes",
      headerName: "Mês",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ano",
      headerName: "Ano",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          {documentType != 'TODOS' && (<>
            <IconButton
              onClick={() => handleDeleteClick(row)}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid #0F71FD",
                borderRadius: '4px',
                marginLeft: 1,
                fontSize: 13,
                padding: '8px',
              }}
            >
              <DeleteOutlineIcon /> EXCLUIR
            </IconButton>

          </>)}

        </Box>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },

    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          {documentType != 'TODOS' && (<>
            <IconButton
              onClick={() => handleDeleteClick(row)}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid #0F71FD",
                borderRadius: '4px',
                marginLeft: 1,
                fontSize: 13,
                padding: '8px',
              }}
            >
              <DeleteOutlineIcon /> EXCLUIR
            </IconButton></>)}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    try {


      const usersRef = ref(database, `db/cidades/${KEY}/${documentType}`);

      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {

          const dados = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));


          dados.sort((a, b) => a.timestamp - b.timestamp);


          dados.reverse();


          setUserData(dados);
        } else {

          setUserData([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, [documentType]);


  const handleAdd = (user) => {
    navigate(`/${KEY}/documentos/editar`, { state: { userData: user } });
  };

  const handleDeleteClick = (news) => {
    setSelectedNews(news);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedNews) {
      const newsRef = ref(database, `db/cidades/${KEY}/${documentType}/${selectedNews.id}`);
      remove(newsRef)
        .then(() => {
          setOpenDialog(false);
          setSelectedNews(null);
        })
        .catch((error) => {
          console.error('Erro ao excluir a notícia:', error);
        });
    }
  };

  return (
    <>

      <Helmet>
        <title>Painel - Documentos</title>
      </Helmet>

      <Box m="20px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/bs.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          {!isMobile ? (<> <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            DOCUMENTOS
          </Typography></>) : (<> <Typography
            variant="h4"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            DOCUMENTOS
          </Typography></>)}


          <Link to={`/${KEY}/documentos/novo`}>
            <IconButton variant="contained" style={{ backgroundColor: "#0F71FD", borderRadius: 4, fontWeight: 'bold', fontSize: 13 }}>
              <AddBoxIcon style={{ marginRight: 4 }} /> NOVO
            </IconButton>
          </Link>



        </Box>

        <Box display="flex" alignItems="center" backgroundColor="#1d1d1d">

          <FormControl variant="filled" fullWidth>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Tipo de Documento</InputLabel>

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_USER ? (<>

              <Select
                value={documentType}
                onChange={handleDocumentTypeChange}
                inputProps={{
                  id: 'document-type-select',
                }}
                style={{ color: colors.grey[100] }}
              >

                <MenuItem value="farmacia">Farmacia Básica</MenuItem>
                <MenuItem value="aldir_blanc">Aldir Blanc</MenuItem>
                <MenuItem value="controleinterno">Controle Interno</MenuItem>
                <MenuItem value="quadro">Quadro Funcional Mensal</MenuItem>
                <MenuItem value="processoseletivo">Processo Seletivo</MenuItem>
                <MenuItem value="portarias">Portarias</MenuItem>
                <MenuItem value="editais">Editais</MenuItem>
                <MenuItem value="leismunicipais">Leis Municipais</MenuItem>
                <MenuItem value="leiorganica">Lei Orgânica</MenuItem>
                <MenuItem value="leipaulogustavo">Lei Paulo Gustavo</MenuItem>
                <MenuItem value="diariooficial">Diário Oficial</MenuItem>
                <MenuItem value="contratos">Contratos</MenuItem>
                <MenuItem value="decretos">Decretos</MenuItem>
                <MenuItem value="loa">LOA</MenuItem>
                <MenuItem value="ldo">LDO</MenuItem>
                <MenuItem value="rgf">RGF</MenuItem>
                <MenuItem value="ppa">PPA</MenuItem>
                <MenuItem value="rreo">RREO</MenuItem>
                <MenuItem value="pca">PCA</MenuItem>
                <MenuItem value="codigotributario">Código Tributário</MenuItem>

              </Select>
            </>) : (<>
              <Select
                value={documentType}
                onChange={handleDocumentTypeChange}
                inputProps={{
                  id: 'document-type-select',
                }}
                style={{ color: colors.grey[100] }}
              >

                <MenuItem value="processoseletivo">Processo Seletivo</MenuItem>
                <MenuItem value="quadro">Quadro Funcional Mensal</MenuItem>


              </Select>
            </>)}


          </FormControl>

        </Box>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columns}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        ) : (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columnsMobile}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )}
      </Box >

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              color: 'white',
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            style={{
              backgroundColor: "#0F71FD",
              fontWeight: 'bold',
              color: 'white',
            }}
            color="error"
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Documentos;
