import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { tokens } from "../../theme";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDatabase, ref, onValue, update, remove } from '@firebase/database';
import HeaderCargos from "../../utils/HeaderCargos";
import firebaseApp, { KEY, KEY_CATEGORIA, KEY_MASTER } from '../../integration/FirebaseConfig';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GoogleIcon from '@mui/icons-material/Google';
import { Helmet } from 'react-helmet';
import { removeAccents } from "../../services/script";


const database = getDatabase(firebaseApp);

const Cargos = () => {
  const [dialogEditOpen, setDialogEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [editedName, setEditedName] = useState("");
  const [editedUser, setEditedUser] = useState(null);
  const [site, setSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [editedCategory, setEditedCategory] = useState('');
  const [editedActive, setEditedActive] = useState(false);
  const [editedMaster, setEditedMaster] = useState(false);
  const [editedAccess, setEditedAccess] = useState(false);
  const [editedLogin, setLogin] = useState(false);
  const [id, setId] = useState(false);
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const [showNotification, setShowNotification] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [filters, setFilters] = useState({
    nome: "",
    email: "",
  });


  const [filteredTableData, setFilteredTableData] = useState([]);

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
    const filteredData = userData.filter((user) => {
      return Object.keys(newFilters).every((key) => {
        if (key === 'data') {
          const formattedFilterDate = newFilters[key] ? new Date(newFilters[key]).toISOString().substr(0, 10) : '';
          const formattedUserData = user[key] ? new Date(user[key]).toISOString().substr(0, 10) : '';
          return !formattedFilterDate || formattedUserData.includes(formattedFilterDate);
        }
        const filterValue = key !== 'data' ? removeAccents(newFilters[key].toLowerCase()) : newFilters[key].toLowerCase();
        const userValue = key !== 'data' && user[key] ? removeAccents(user[key].toLowerCase()) : user[key];
        return !newFilters[key] || (typeof user[key] === 'string' && userValue.includes(filterValue));
      });
    });

    if (filteredData.length <= 0) {
      setShowNotification(true);
    }
    setFilteredTableData(filteredData);
  };


  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    try {
      const usersRef = ref(database, `/usuarios/`);
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const filteredUsers = Object.keys(data)
            .map((key) => ({
              id: key,
              ...data[key],
            }))
            .filter((user) => user.cidade === KEY);
          setUserData(filteredUsers);
        } else {
          setUserData([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, []);

  useEffect(() => {
    try {
      const usersRef = ref(database, `/sites/`);
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const formattedSites = Object.keys(data).map(id => ({ id, value: data[id] }));
          setSites(formattedSites);
        } else {
          setSites([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, []);


  const CustomRenderCell = ({ value }) => {
    if (value === null || value === undefined) {
      return (
        <Typography style={{ color: 'white', fontWeight: 'bold' }}>
          -
        </Typography>
      );
    }

    if (typeof value === 'boolean') {
      return (
        <Typography
          style={{ color: value ? '#0F71FD' : 'red', fontWeight: 'bold' }}
        >
          {value ? <CheckCircleOutlineIcon fontSize="large" /> : ''}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{ color: 'white', }}
        >
          {value.toUpperCase()}
        </Typography>
      );
    }
  };


  const columns = [
    {
      field: 'nome',
      headerName: 'Nome',

      flex: 1,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
      valueFormatter: (params) => params.value.toUpperCase()
    },
    {
      field: 'cpf',
      headerName: 'CPF',

      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },

    {
      field: 'site',
      headerName: 'SITE',

      flex: 1,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },

    {
      field: 'horaLogin',
      headerName: 'Ultimo Login',
      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'categoria',
      headerName: 'Cargo',

      flex: 0.5,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },
    {
      field: 'acesso',
      headerName: 'Acesso',
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
    },


    {
      field: 'licensa',
      headerName: 'License',
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      filterable: true,
      renderCell: ({ row }) => {
     
    
        const handleCopy = () => {
          navigator.clipboard.writeText(row.licensa).then(() => {
            setCopied(true);
         
          });
        };
    
        return (
          <IconButton
            sx={{
              backgroundColor: "transparent",
              border: "1px solid red",
              borderRadius: '4px',
              marginLeft: 1,
              fontSize: 13,
              padding: '8px',
            }}
            onClick={handleCopy}
          >
            {row.licensa}
          </IconButton>
        );
      
      },
      hide: !KEY_MASTER 
    },
    

    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleEdit(row.id)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              padding: '8px',
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
          {KEY_MASTER === true && KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (<>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid red",
                borderRadius: '4px',
                marginLeft: 1,
                fontSize: 13,
                padding: '8px',
              }}
            >
              <DeleteOutlineIcon /> DELETAR
            </IconButton>
          </>)}

        </Box>
      ),
    },
  ];

  const columnsMobile = [

    {
      field: 'nome',
      headerName: 'Nome',

      flex: 1,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
      valueFormatter: (params) => params.value.toUpperCase(),
    },

    {
      field: 'categoria',
      headerName: 'Categoria',

      flex: 0.5,
      cellClassName: 'name-column--cell',
      filterable: true,
      renderCell: ({ value }) => <CustomRenderCell value={value} />,
      valueFormatter: (params) => params.value.toUpperCase(),
    },


    {
      field: "edit",
      headerName: "Ações",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleEdit(row.id)}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              borderRadius: '4px',
              padding: '8px',
            }}
          >
            <EditOutlinedIcon />
          </IconButton>

          {KEY_MASTER === true && KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (<>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid red",
                borderRadius: '4px',
                marginLeft: 1,
                fontSize: 13,
                padding: '8px',
              }}
            >
              <DeleteOutlineIcon /> DELETAR
            </IconButton>
          </>)}
        </Box>
      ),
    },
  ];

  const handleDeleteConfirm = () => {

    if (KEY_MASTER === true && KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER) {
      const newsRef = ref(database, `/usuarios/${id}`);
      remove(newsRef)
        .then(() => {
          setOpenDialogDelete(false);
        })
        .catch((error) => {
          console.error('Erro ao excluir', error);
        });
    }


  };

  const handleDeleteClick = (id) => {
    setId(id)
    setOpenDialogDelete(true);
  };

  const handleSaveEdit = () => {
    const userRef = ref(database, `usuarios/${selectedUser.id}`);

    if (editedAccess === false) {
      update(userRef, {
        nome: editedName,
        categoria: editedCategory,
        ativo: editedActive,
        acesso: editedAccess,
        master: editedMaster,
        site: site,
        token: "0"
      })
        .then(() => {
          console.log("Usuário atualizado com sucesso");

          handleCloseEditDialog();
        })
        .catch((error) => {
          console.error("Erro ao atualizar usuário:", error);
        });
    } else {
      update(userRef, {
        nome: editedName,
        categoria: editedCategory,
        ativo: editedActive,
        acesso: editedAccess,
        master: editedMaster,
        site: site,

      })
        .then(() => {
          console.log("Usuário atualizado com sucesso");

          handleCloseEditDialog();
        })
        .catch((error) => {
          console.error("Erro ao atualizar usuário:", error);
        });
    }


  };

  const handleEdit = (id) => {
    const userToEdit = userData.find((user) => user.id === id);
    setSelectedUser(userToEdit);
    setEditedUser(userToEdit);
    setSite(userToEdit.site);
    setEditedName(userToEdit.nome);
    setEditedCategory(userToEdit.categoria);
    setEditedActive(userToEdit.ativo);
    setEditedAccess(userToEdit.acesso);
    setEditedMaster(userToEdit.master);
    setLogin(userToEdit.horaLogin);
    setDialogEditOpen(true);
  };

  const handleCloseEditDialog = () => {
    setDialogEditOpen(false);
    setEditedName("");
  };

  return (
    <>
      <Helmet>
        <title>Painel - Usuarios</title>
      </Helmet>

      <Box m="20px">
        <Dialog open={dialogEditOpen} onClose={handleCloseEditDialog}>
          <DialogTitle>Editar Usuario</DialogTitle>
          <DialogContent>
            <Typography variant="h6" sx={{ mb: 1, color: "#0F71FD", fontFamily: "Arial, sans-serif", fontSize: "0.8rem", fontWeight: 'bold' }}>
              Nome do Usuario
            </Typography>
            <TextField
              label="Nome"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              fullWidth
            />

            <Typography variant="h6" sx={{ mb: 1, color: "#0F71FD", fontFamily: "Arial, sans-serif", fontSize: "0.8rem", fontWeight: 'bold' }}>
              Site de acesso:
            </Typography>
            <Select
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              {sites.map((siteItem) => (
                <MenuItem key={siteItem.id} value={siteItem.value}>
                  {siteItem.value}
                </MenuItem>
              ))}
            </Select>

            <Typography variant="h6" sx={{ mb: 1, color: "#0F71FD", fontFamily: "Arial, sans-serif", fontSize: "0.8rem", fontWeight: 'bold' }}>
              Último Login:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#FFF",
                fontFamily: "Arial, sans-serif",
                fontSize: "1rem",
                fontWeight: 'normal',
                backgroundColor: "#333",
                padding: "8px",
                borderRadius: "4px"
              }}
            >
              {editedLogin}
            </Typography>


            <Typography variant="h6" sx={{ mb: 1, color: "#0F71FD", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "0.8rem", fontWeight: 'bold' }}>
              Cargo do Usuario
            </Typography>
            <FormControl fullWidth>
              <InputLabel></InputLabel>


              {KEY_MASTER === true ? (<>

                <Select
                  value={editedCategory}
                  onChange={(e) => setEditedCategory(e.target.value)}
                >
                  <MenuItem value="Admin">Administrador</MenuItem>
                  <MenuItem value="Licitação">Licitações</MenuItem>
                  <MenuItem value="RH">Recursos Humanos</MenuItem>
                  <MenuItem value="OperadorAdmin">Operador Admintrativo</MenuItem>
                  <MenuItem value="Operador">Operador de Publicações</MenuItem>
                  <MenuItem value="Midia/Marketing">Gestão de Mídia</MenuItem>
                  <MenuItem value="Sala do Empreendedor">Sala do Empreendedor</MenuItem>

                </Select>


              </>) : (<>
                {editedCategory === process.env.REACT_APP_KEY_ADMIN_USER ? (<>
                  <Typography variant="h6" sx={{ mb: 1, color: "white", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "0.6rem", fontWeight: 'bold' }}>
                    Você não pode alterar o cargo deste usuário.
                  </Typography>
                </>) : (<>
                  <Select
                    value={editedCategory}
                    onChange={(e) => setEditedCategory(e.target.value)}
                  >
                    <MenuItem value="Licitação">Licitações</MenuItem>
                    <MenuItem value="RH">Recursos Humanos</MenuItem>
                    <MenuItem value="OperadorAdmin">Operador Admintrativo</MenuItem>
                    <MenuItem value="Operador">Operador de Publicações</MenuItem>
                    <MenuItem value="Midia/Marketing">Gestão de Mídia</MenuItem>
                    <MenuItem value="Sala do Empreendedor">Sala do Empreendedor</MenuItem>
                  </Select>
                </>)}

              </>)}

            </FormControl>


            <Typography variant="h6" sx={{ mb: 1, color: "#0F71FD", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "0.8rem", fontWeight: 'bold' }}>
              Liberação do Sistema

            </Typography>


            {KEY_MASTER === true ? (<>

              <FormControlLabel
                control={<Checkbox checked={editedActive} onChange={(e) => setEditedActive(e.target.checked)} />}
                label="Ativo"
              />
              <FormControlLabel
                control={<Checkbox checked={editedAccess} onChange={(e) => setEditedAccess(e.target.checked)} />}
                label="Acesso"
              />

            </>) : (<>
              {editedCategory === process.env.REACT_APP_KEY_ADMIN_USER ? (<>
                <Typography variant="h6" sx={{ mb: 1, color: "white", marginTop: "10px", fontFamily: "Arial, sans-serif", fontSize: "0.6rem", fontWeight: 'bold' }}>
                  Você não pode alterar acesso deste usuário.
                </Typography>
              </>) : (<>

                <FormControlLabel
                  control={<Checkbox checked={editedActive} onChange={(e) => setEditedActive(e.target.checked)} />}
                  label="Ativo"
                />
                <FormControlLabel
                  control={<Checkbox checked={editedAccess} onChange={(e) => setEditedAccess(e.target.checked)} />}
                  label="Acesso"
                />
              </>)}

            </>)}



            {KEY_MASTER === true && (<>
              <FormControlLabel
                control={<Checkbox checked={editedMaster} onChange={(e) => setEditedMaster(e.target.checked)} />}
                label="Master"
              />
            </>)}


          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: "white", fontWeight: 'bold' }} onClick={handleCloseEditDialog}>Cancelar</Button>
            <Button onClick={handleSaveEdit} color="primary" style={{ backgroundColor: "#0F71FD", color: "#FFF", fontWeight: 'bold' }}>Salvar</Button>
          </DialogActions>
        </Dialog>
        <HeaderCargos title={`AUTH`} onFilterSelect={handleFilterSelect} onFilterApply={handleFilterApply}></HeaderCargos>
        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid rows={filteredTableData} columns={columns} rowHeight={80} hideFooter={true} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            ) : (
              <DataGrid rows={filteredTableData.length > 0 ? filteredTableData : userData} columns={columns} rowHeight={80} hideFooter={true} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            )}
          </Box>
        ) : (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',

              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            {showNotification ? (
              <DataGrid rows={filteredTableData} columns={columnsMobile} rowHeight={80} hideFooter={true} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            ) : (
              <DataGrid rows={filteredTableData.length > 0 ? filteredTableData : userData} columns={columnsMobile} rowHeight={80} hideFooter={true} localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            )}
          </Box>
        )}
      </Box>

      <Dialog open={openDialogDelete} onClose={() => setOpenDialogDelete(false)}>
        <DialogTitle>Confirmação de Exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialogDelete(false)}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #0F71FD",
              color: 'white',
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            style={{
              backgroundColor: "#0F71FD",
              fontWeight: 'bold',
              color: 'white',
            }}
            color="error"
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cargos;
