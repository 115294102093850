import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel, IconButton,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { getDatabase, ref, push } from "firebase/database";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';
import { UploadFile } from "@mui/icons-material";

const database = getDatabase(firebaseApp);

const NovoAvisoSala = () => {
  const [titulo, setTitulo] = useState('');
  const [data, setData] = useState('');
  const [descricao, setDescricao] = useState('');
  const [numero, setNumero] = useState('');
  const [documento, setDocumento] = useState('saladoempreendedor');
  const [horario, setHorario] = useState('');
  const [modalidade, setModalidade] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [baixavel, setBaixavel] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const modalidadeConst = [
    "Sala do Empreendedor"
  ];

  const horarioConst = [
    "Em até 1 dia útil", "Durante todo o dia", "Durante varios dias", "08:00h", "09:00h", "09:30h", '10:00h', '11:00h', "11:30h", "12:00h", "12:30h", "13:00h", "13:30h", "17:00h", "17:30h", "19:00h"
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let documentoUrl = '';
      if (baixavel && selectedFile) {
        const file = selectedFile;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('documento', documento);

        const response = await fetch(`${KEY_SITE}upload.php`, {
          method: 'POST',
          body: formData
        });

        const result = await response.json();

        if (result.error) {
          throw new Error(result.error);
        }

        documentoUrl = result.filePath;
      } else if (isLink && linkUrl) {
        documentoUrl = linkUrl;
      }

      if(isLink == true){
        const noticiasRef = ref(database, `db/cidades/${KEY}/${documento}`);
        const novaNoticiaRef = await push(noticiasRef, {
          titulo,
          data,
          descricao,
          numero,
          horario,
          modalidade,
          ativo,
          baixavel: true,
          documentoUrl,
        });

        console.log('Novo ID de Documento:', novaNoticiaRef.key);

      } else{
        const noticiasRef = ref(database, `db/cidades/${KEY}/${documento}`);
        const novaNoticiaRef = await push(noticiasRef, {
          titulo,
          data,
          descricao,
          numero,
          horario,
          modalidade,
          ativo,
          baixavel,
          documentoUrl,
        });

        console.log('Novo ID de Documento:', novaNoticiaRef.key);
      }

     

      navigate(`/${KEY}/salaempreendedor`);

    
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
    }
  };

  return (
    <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
      <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
        AVISO SALA DO EMPREENDEDOR
      </Divider>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Titulo"
              value={titulo}
              style={{ color: 'white' }}
              onChange={(e) => setTitulo(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Descrição"
              value={descricao}
              style={{ color: 'white' }}
              onChange={(e) => setDescricao(e.target.value)}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              required
              rows={4}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Destaque"
              value={numero}
              style={{ color: 'white' }}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              onChange={(e) => setNumero(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Data"
              value={data}
              InputLabelProps={{ style: { color: "#FFF" } }}
              InputProps={{ style: { color: "#FFF" } }}
              style={{ color: 'white' }}
              onChange={(e) => setData(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="horario-label" style={{ color: 'white' }}>Horario</InputLabel>
              <Select
                style={{ color: 'white' }}
                labelId="horario-label"
                value={horario}
                InputLabelProps={{ style: { color: "#FFF" } }}
                InputProps={{ style: { color: "#FFF" } }}
                onChange={(e) => setHorario(e.target.value)}
                label="Horario"
              >
                {horarioConst.map((horario, index) => (
                  <MenuItem key={index} value={horario}>{horario}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel id="modalidade-label" style={{ color: 'white' }}>Modalidade</InputLabel>
              <Select
                style={{ color: 'white' }}
                labelId="modalidade-label"
                value={modalidade}
                InputLabelProps={{ style: { color: "#FFF" } }}
                InputProps={{ style: { color: "#FFF" } }}
                onChange={(e) => setModalidade(e.target.value)}
                label="Modalidade"
              >
                {modalidadeConst.map((modalidade, index) => (
                  <MenuItem key={index} value={modalidade}>{modalidade}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={baixavel}
                  onChange={() => {
                    setBaixavel(true);
                    setIsLink(false);
                  }}
                  name="baixavel"
                  color="primary"
                />
              }
              label="Baixável"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLink}
                  onChange={() => {
                    setIsLink(true);
                    setBaixavel(false);
                  }}
                  name="link"
                  color="primary"
                />
              }
              label="Link"
            />
          </Grid>

          {baixavel && (
            <Grid item xs={12}>
              <input
                accept=".doc,.docx,.png,.jpg,.jpeg,.pdf"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <IconButton color="primary" aria-label="upload file" component="span">
                  <UploadFile style={{ color: "#0F71FD", fontSize: '40px' }} />
                </IconButton>
                {selectedFile && <Typography style={{ display: 'inline', color: "#FFF" }}>Arquivo selecionado: {selectedFile.name}</Typography>}
              </label>
            </Grid>
          )}

          {isLink && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Link URL"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                InputLabelProps={{ style: { color: "#FFF" } }}
                InputProps={{ style: { color: "#FFF" } }}
                required
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Salvar e Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NovoAvisoSala;
