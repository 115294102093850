import React from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from "./authentication/Login/Login";
import FirstCreen from "./authentication/Cadastro/FirstScreen";
import Deslogar from "./authentication/Logout/Deslogar";
import Acesso from "./authentication/Acesso/index";
import Manutencao from "./authentication/Manutencao";
import Sidebar from "./utils/SideBar";
import Cargos from "./components/Cargos";
import AuthProvider from "./security/AuthProvider";
import Documentos from "./components/documentos";
import NovaNoticia from "./components/noticias/Novanoticia";
import NovoDocumento from "./components/documentos/NovaInstituicao";
import Noticias from "./components/noticias";
import Licitacoes from "./components/licitacoes";
import SidebarMobile from "./utils/SideBarMobile";
import NovaLicitacao from "./components/licitacoes/NovaLicitacao";
import Avisos from "./components/avisosSociais";
import NovoAviso from "./components/avisosSociais/NovoAviso";
import EditDocumento from "./components/documentos/EditDocumento";
import EditNoticia from "./components/noticias/EditNoticia";
import Veiculos from "./components/veiculos";
import NovoVeiculo from "./components/veiculos/NovoVeiculo/Novo";
import DocumentosLegislativo from "./components/legislativo";
import NovoLegislativo from "./components/legislativo/NovaInstituicao";
import Vereadores from "./components/vereadores";
import EditLegislacao from "./components/legislativo/EditDocumento";
import NovoVereador from "./components/vereadores/NovoVereador";
import EditVereador from "./components/vereadores/EditarVereador";
import AvisosSalaEmpreendedor from "./components/salaEmpreendedor";
import NovoAvisoSala from "./components/salaEmpreendedor/NovoAviso";
import Config from "./components/Config";
import ESIC from "./components/esic";
import EditESIC from "./components/esic/EditDocumento";

const App = () => {
  const [theme, colorMode] = useMode();
  const isMobile = useMediaQuery("(max-width: 700px)");
  const { isAuthenticated, admin, licitacao, RH, OperadorAdmin,
    Operador,
    MidiaMarketing, SalaEmpreendedor, acesso, manutencaoAtiva, cidade, loading, isSidebarExpanded, handleSidebarToggle } = AuthProvider();

  if (loading === false) {
    if (isAuthenticated === true) {
      if (manutencaoAtiva === false) {
        if (acesso) {
          if (admin) {
            return ( 

              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px", transition: 'margin-left 0.3s ease, width 0.3s ease' }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />
                            <Route path={`/${cidade}/usuarios`} element={<Cargos />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />

                            <Route path={`/${cidade}/veiculos`} element={<Veiculos />} />
                            <Route path={`/${cidade}/veiculos/novo`} element={<NovoVeiculo />} />

                            <Route path={`/${cidade}/e-sic`} element={<ESIC />} />
                            <Route path={`/${cidade}/e-sic/editar`} element={<EditESIC />} />
                           

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path={`/${cidade}/configurar`} element={<Config />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />
                            <Route path={`/${cidade}/usuarios`} element={<Cargos />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />

                            <Route path={`/${cidade}/veiculos`} element={<Veiculos />} />
                            <Route path={`/${cidade}/veiculos/novo`} element={<NovoVeiculo />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path={`/${cidade}/configurar`} element={<Config />} />


                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}


                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (licitacao) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>

                            <Route path={`/`} element={<Licitacoes />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />


                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>

                            <Route path={`/`} element={<Licitacoes />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />



                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (RH) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>

                            <Route path={`/`} element={<Documentos />} />
                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />

                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>

                            <Route path={`/`} element={<Documentos />} />
                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (OperadorAdmin) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>
                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />
                            <Route path={`/${cidade}/usuarios`} element={<Cargos />} />


                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />

                            <Route path={`/${cidade}/veiculos`} element={<Veiculos />} />
                            <Route path={`/${cidade}/veiculos/novo`} element={<NovoVeiculo />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />
                            

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path={`/${cidade}/configurar`} element={<Config />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>
                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />
                            <Route path={`/${cidade}/usuarios`} element={<Cargos />} />


                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />

                            <Route path={`/${cidade}/veiculos`} element={<Veiculos />} />
                            <Route path={`/${cidade}/veiculos/novo`} element={<NovoVeiculo />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/licitacoes`} element={<Licitacoes />} />
                            <Route path={`/${cidade}/licitacoes/novo`} element={<NovaLicitacao />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (Operador) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>
                            <Route path={`/`} element={<Documentos />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>
                            <Route path={`/`} element={<Documentos />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/documentos`} element={<Documentos />} />
                            <Route path={`/${cidade}/documentos/editar`} element={<EditDocumento />} />
                            <Route path={`/${cidade}/documentos/novo`} element={<NovoDocumento />} />

                            <Route path={`/${cidade}/legislativo`} element={<DocumentosLegislativo />} />
                            <Route path={`/${cidade}/legislativo/editar`} element={<EditLegislacao />} />
                            <Route path={`/${cidade}/legislativo/novo`} element={<NovoLegislativo />} />

                            <Route path={`/${cidade}/vereadores`} element={<Vereadores />} />
                            <Route path={`/${cidade}/vereadores/novo`} element={<NovoVereador />} />
                            <Route path={`/${cidade}/vereadores/editar`} element={<EditVereador />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (MidiaMarketing) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          } else if (SalaEmpreendedor) {
            return (
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app">
                    {!isMobile ?
                      (<>
                        <Sidebar onToggle={handleSidebarToggle} className="sidebar-transition" />
                        <main className="content sidebar-transition" style={{ marginLeft: isSidebarExpanded ? "260px" : "80px" }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main>

                      </>) :
                      (<>
                        <SidebarMobile onToggle={handleSidebarToggle} />
                        <main className="content" style={{ marginLeft: isSidebarExpanded ? "00px" : "00px" }}>
                          <Routes>

                            <Route path={`/`} element={<Noticias />} />
                            <Route path={`/${cidade}/logout`} element={<Deslogar />} />

                            <Route path={`/${cidade}/noticias`} element={<Noticias />} />
                            <Route path={`/${cidade}/noticias/novo`} element={<NovaNoticia />} />
                            <Route path={`/${cidade}/noticias/editar`} element={<EditNoticia />} />

                            <Route path={`/${cidade}/salaempreendedor`} element={<AvisosSalaEmpreendedor />} />
                            <Route path={`/${cidade}/salaempreendedor/novo`} element={<NovoAvisoSala />} />

                            <Route path={`/${cidade}/avisos`} element={<Avisos />} />
                            <Route path={`/${cidade}/avisos/novo`} element={<NovoAviso />} />

                            <Route path="*" element={<Navigate to="/" />} />
                          </Routes>
                        </main></>)}
                  </div>
                </ThemeProvider>
              </ColorModeContext.Provider>
            );
          }
        } else {
          return (
            <ColorModeContext.Provider value={colorMode}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                  <main className="content">
                    <Routes>
                      <Route path={`/`} element={<Acesso />} />
                      <Route path={`/${cidade}/`} element={<Acesso />} />
                      <Route path={`/logout`} element={<Deslogar />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </main>
                </div>
              </ThemeProvider>
            </ColorModeContext.Provider>
          );
        }
      } else {

        return (
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className="app">
                <main className="content">
                  <Routes>
                    <Route path={`/`} element={<Manutencao />} />
                    <Route path={`/logout`} element={<Deslogar />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </main>
              </div>
            </ThemeProvider>
          </ColorModeContext.Provider>
        );

      }
    } else {
      return (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <main
                className="content"

              >

                <Routes>
                  <Route path="/*" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/cadastro" element={<FirstCreen />} />

                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      );

    }
  } else {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ display: 'flex', backgroundColor: "black", justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <img src="/images/gurjao.webp" alt="Carregando..." style={{ maxWidth: '40%', maxHeight: '30%', borderRadius: 20, padding: 50 }} />
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
};

export default App;