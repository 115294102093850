import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import firebaseApp from '../../integration/FirebaseConfig';

const Deslogar = () => {
    const navigate = useNavigate();

    const auth = getAuth(firebaseApp);
    signOut(auth).then(() => {
        navigate("/login");
      
    }).catch((error) => {

        console.error("Erro ao fazer logout:", error);
    });


};

export default Deslogar;