import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const UPDATE = process.env.REACT_APP_VERSION;
export const instance_id = process.env.REACT_APP_INSTANCE_ID;
export const instance_token = process.env.REACT_APP_INSTANCE_TOKEN;
export const user_token_id = process.env.REACT_APP_USER_TOKEN_ID;
export const url_api_gzappy = process.env.REACT_APP_URL_API_GZAPPY;
export const url_api_gzappy_media = process.env.REACT_APP_URL_API_GZAPPY_SEND_MEDIA;
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage();
export let KEY = 0;
export let KEY_NOME = 0;
export let KEY_UF = 0;
export let KEY_ACESSO = false;
export let KEY_CATEGORIA = "";
export let NAME = "";
export let VERSION = "";
export let DATA_LOGIN = "";
export let KEY_SITE = "";
export let KEY_LICENSE = "";
export let KEY_MASTER = false;

export function getCidadeEKeyNomeUsuarioLogado() {
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userId = user.uid;
                const userRef = ref(database, `usuarios/${userId}`);
                onValue(userRef, (snapshot) => {
                    const userData = snapshot.val();
                    if (userData) {
                        const cidade = userData.cidade;
                        const keyName = userData.KEY_NAME;
                        KEY_ACESSO = userData.acesso;
                        KEY = cidade;
                        DATA_LOGIN = userData.horaLogin
                        NAME = userData.nome
                        KEY_CATEGORIA = userData.categoria;
                        KEY_SITE = userData.site;
                        KEY_NOME = userData.KEY_NAME;
                        KEY_UF = userData.estado
                        if (userData.licensa) {
                            KEY_LICENSE = userData.licensa
                        }

                        if (userData.master != null && userData.master == true) {
                            KEY_MASTER = true
                        }

                        resolve({ cidade, keyName });
                    }
                });
            }
        });
    });
}

getCidadeEKeyNomeUsuarioLogado().then(({ cidade, keyName }) => {
  /*   console.log('KEY:', cidade); */

}).catch((error) => {
    console.error(error);
});


export default firebaseApp;