import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Backdrop,
} from "@mui/material";
import { getDatabase, ref, push } from "firebase/database";
import { UploadFile } from "@mui/icons-material";
import firebaseApp, { KEY, KEY_CATEGORIA, KEY_SITE } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

const NovoLegislativo = () => {
  const [nome, setNome] = useState('');
  const [documento, setDocumento] = useState('');
  const [ano, setAno] = useState('');
  const [mes, setMes] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); 
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!selectedFile) {
      alert('Por favor, selecione um arquivo antes de enviar.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('documento', documento);

    try {

      const response = await fetch(`${KEY_SITE}upload.php`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.error) {
        throw new Error(result.error);
      }

      const filePath = result.filePath;
      const documentosRef = ref(database, `db/cidades/${KEY}/${documento}`);
      const novoDocumentoRef = await push(documentosRef, {
        nome,
        href: filePath,
        documentoUrl: filePath,
        ano,
        documento,
        mes,
      });
      setLoading(false);
      navigate(`/${KEY}/legislativo`);

      console.log('Novo ID de Documento:', novoDocumentoRef.key);

    } catch (error) {
      setLoading(false);
      console.error('Erro ao enviar arquivo:', error);
    }
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/gurjao.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />

        </Box>
      </Backdrop>

      <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
        <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
          NOVO DOC. LEGISLATIVO
        </Divider>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                InputLabelProps={{ style: { color: "#FFF" } }}
                InputProps={{ style: { color: "#FFF" } }}
                required
              />
            </Grid>



            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="documento-label" style={{ color: 'white' }}>Tipo de documento</InputLabel>
                <Select
                  style={{ color: 'white' }}
                  labelId="documento-label"
                  value={documento}
                  onChange={(e) => setDocumento(e.target.value)}
                  label="Tipo de documento"
                >
                  <MenuItem value="projetodelei">Projetos de Leis</MenuItem>
                  <MenuItem value="pedidos_info">Pedidos de Informações</MenuItem>
                  <MenuItem value="mocoes">Moções</MenuItem>
                  <MenuItem value="requerimentos">Requerimentos</MenuItem>
                </Select>
              </FormControl>
            </Grid>



            <Grid item xs={4}>
              <input
                accept=".doc,.docx,.png,.jpg,.jpeg,.pdf"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <IconButton color="primary" aria-label="upload file" component="span">
                  <UploadFile style={{ color: "#0F71FD", fontSize: '30px' }} />
                </IconButton>
                {selectedFile && <Typography style={{ display: 'inline', color: "#FFF" }}>Arquivo selecionado: {selectedFile.name}</Typography>}
              </label>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="mes-label" style={{ color: 'white' }}>Mês</InputLabel>
                <Select
                  labelId="mes-label"
                  value={mes}
                  onChange={(e) => setMes(e.target.value)}
                  label="Mês"
                  style={{ color: 'white' }}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  <MenuItem value="janeiro">Janeiro</MenuItem>
                  <MenuItem value="fevereiro">Fevereiro</MenuItem>
                  <MenuItem value="marco">Março</MenuItem>
                  <MenuItem value="abril">Abril</MenuItem>
                  <MenuItem value="maio">Maio</MenuItem>
                  <MenuItem value="junho">Junho</MenuItem>
                  <MenuItem value="julho">Julho</MenuItem>
                  <MenuItem value="agosto">Agosto</MenuItem>
                  <MenuItem value="setembro">Setembro</MenuItem>
                  <MenuItem value="outubro">Outubro</MenuItem>
                  <MenuItem value="novembro">Novembro</MenuItem>
                  <MenuItem value="dezembro">Dezembro</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="ano-label" style={{ color: 'white' }}>Ano</InputLabel>
                <Select
                  labelId="ano-label"
                  value={ano}
                  onChange={(e) => setAno(e.target.value)}
                  label="Ano"
                  style={{ color: 'white' }}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Salvar e Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default NovoLegislativo;
