import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Backdrop,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { getDatabase, ref, update } from "firebase/database";
import firebaseApp, { KEY } from '../../../integration/FirebaseConfig';

const database = getDatabase(firebaseApp);

const EditESIC = () => {
  const location = useLocation();
  const { userData, documentType } = location.state || {};
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    name: userData?.name || '',
    email: userData?.email || '',
    date: userData?.date || '',
    description: userData?.description  || userData?.message || '',
    requestType: userData?.requestType || userData?.letterType || 'Mensagem Ouvidoria',
    status: userData?.status || '',
    ticket: userData?.ticket || '',
    retorno: userData?.retorno || '', 
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleSave = () => {
    setLoading(true);
    const documentRef = ref(database, `db/cidades/${KEY}/${documentType}/${userData.id}`);
    
    update(documentRef, { status: formData.status, ...formData })
      .then(() => {
        setLoading(false);
        navigate(-1);  // Retorna para a página anterior
      })
      .catch((error) => {
        console.error("Erro ao atualizar o documento:", error);
        setLoading(false);
      });
  };
  

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: 999, color: '#fff' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src="/images/gurjao.webp" alt="Carregando..." style={{ maxWidth: '50%', maxHeight: '50%', borderRadius: 20, padding: 50 }} />
        </Box>
      </Backdrop>

      <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px">
        <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
          EDITAR E-SIC
        </Divider>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nome"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="E-mail"
              name="email"
              value={formData.email}
              InputProps={{ readOnly: true }}
              variant="outlined"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Data"
              name="date"
              value={formData.date}
              InputProps={{ readOnly: true }}
              variant="outlined"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Tipo de Requisição"
              name="requestType"
              value={formData.requestType}
              InputProps={{ readOnly: true }}
              variant="outlined"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: '#0F71FD' }}>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                sx={{ color: '#fff' }}
              >
                <MenuItem value="Criado">Criado</MenuItem>
                <MenuItem value="Em andamento">Em andamento</MenuItem>
                <MenuItem value="Concluído">Concluído</MenuItem>
                <MenuItem value="Pendente">Pendente</MenuItem>
                <MenuItem value="Cancelado">Cancelado</MenuItem>
                <MenuItem value="Revisão">Revisão</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Ticket"
              name="ticket"
              value={formData.ticket}
              onChange={handleInputChange}
              variant="outlined"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              rows={Math.min(10, formData.description.length / 50 + 3)} // Ajusta as linhas conforme o tamanho do texto
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Retorno"
              name="retorno"
              value={formData.retorno}
              onChange={handleInputChange}
              variant="outlined"
              placeholder="Digite um link ou mensagem de retorno"
              InputLabelProps={{ style: { color: '#0F71FD' } }}
              sx={{ input: { color: '#fff' } }}
            />
          </Grid>
        </Grid>

        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditESIC;
