import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    Divider,
    Input,
    Select,
    MenuItem,
    FormControl,
    InputLabel, IconButton
} from "@mui/material";
import { getDatabase, ref, push } from "firebase/database";
import firebaseApp, { KEY, KEY_SITE } from '../../../integration/FirebaseConfig';
import { UploadFile } from "@mui/icons-material";
const database = getDatabase(firebaseApp);

const NovoVeiculo = () => {
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [cor, setCor] = useState('');
    const [secretaria, setSecretaria] = useState('');
    const [ano, setAno] = useState('');
    const [placa, setPlaca] = useState('');
    const navigate = useNavigate();


    const marcasConhecidas = [
        'Retroescavadeira', 'Pá Enchedeira', 'Patrol',
        'Chevrolet', 'Ford', 'Toyota', 'Honda', 'Volkswagen', 'Nissan', 'BMW', 'Mercedes-Benz', 'Audi', 'Hyundai',
        'Kia', 'Mazda', 'Subaru', 'Fiat', 'Volvo', 'Jaguar', 'Land Rover', 'Porsche', 'Lexus', 'Jeep',
        'Chrysler', 'Dodge', 'Ram', 'Buick', 'Cadillac', 'GMC', 'Tesla', 'Mitsubishi', 'Acura', 'Infiniti', 'Volare', 'Iveco'
    ];

    const modelosConhecidos = [
        "POP 100", "Fiorino", "Ducato",
        'Caminhão D60', 'CG FAN 150', 'CG 125', 'CG 160', 'Onibus Marcopolo', 'City Class', "Onibus 15.190", 'OF 1519', "Onibus", "ATRON 2729", "26.280", "Maquina Pesada",
        'Civic', 'Accord', 'CR-V', 'Pilot', 'Camry', 'Corolla', 'Rav4', 'Highlander', 'Golf', 'Passat',
        'Tiguan', 'Atlas', 'Jetta', 'Mustang', 'F-150', 'Escape', 'Explorer', 'Silverado', 'Equinox', 'Traverse',
        '3 Series', '5 Series', 'X3', 'X5', 'A3', 'A4', 'Q5', 'Q7', 'Sonata', 'Santa Fe',
        'Soul', 'Sportage', 'Sorento', 'Mazda3', 'Mazda6', 'CX-5', 'CX-9', 'Outback', 'Forester', 'Legacy',
        '500', 'Panda', '500X', '500L', 'XC60', 'XC90', 'S60', 'S90', 'F-Pace', 'E-Pace',
        'Range Rover', 'Macan', 'Cayenne', '911', 'Civic', 'Fit', 'CR-V', 'HR-V', 'Ridgeline', 'Pilot',
        'Cherokee', 'Grand Cherokee', 'Wrangler', 'Ram 1500', 'Ram 2500', 'Durango', 'Challenger', 'Charger', 'Aviator',
        'Navigator', 'Escalade', 'XT5', 'XT6', 'Camaro', 'Malibu', 'Silverado', 'Equinox', 'Traverse', 'Tahoe',
        'C-Class', 'E-Class', 'S-Class', 'GLA', 'GLC', 'GLE', 'A3', 'A4', 'Q5', 'Q7',
        'Elantra', 'Kona', 'Palisade', 'Tucson', 'Niro', 'Seltos', 'Telluride', 'Mazda3', 'Mazda6', 'CX-30',

        // Modelos Fiat
        'Uno', 'Palio', 'Siena', 'Strada', 'Punto', 'Mobi', 'Argo', 'Cronos', 'Toro', '500',
        '500X', '500L', 'Doblo', 'Freemont', 'Linea', 'Bravo', 'Grand Siena', 'Stilo', 'Cinquecento',
        'Panda', 'Tipo', 'Fullback', '124 Spider', 'X1/9', 'Regata', 'Tempra', 'Coupe', 'Brava', 'Marea',

        // Modelos Volkswagen
        'GOL', , 'GOL MPI', 'Golf', 'Polo', 'Jetta', 'Passat', 'Tiguan', 'Atlas', 'Touareg', 'Arteon', 'Up', 'Fox',
        'Beetle', 'T-Cross', 'T-Roc', 'Amarok', 'Caddy', 'California', 'Caravelle', 'Transporter', 'Eos', 'Scirocco',
        'Phaeton', 'Bora', 'Lupo', 'Sharan', 'Corrado', 'Touran', 'Multivan', 'CC', 'ID.3', 'ID.4',

        // Modelos Chevrolet
        'Camaro', 'Corvette', 'Malibu', 'Impala', 'Cruze', 'Spark', 'Sonic', 'Volt', 'Equinox', 'Trax',
        'Blazer', 'Traverse', 'Tahoe', 'Suburban', 'Colorado', 'Silverado', 'Express', 'Aveo', 'Captiva', 'Orlando',
        'Onix', 'Prisma', 'Cobalt', 'Spin', 'Tracker', 'Trailblazer', 'S10', 'Montana', 'Astra', 'Vectra',
        'Corsa', 'Celta', 'Agile', 'Meriva', 'Zafira', 'Cruze', 'Classic', 'Cobalt', 'Onix', 'Spin',
    ];

    const anosConhecidos = [
        '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014',
        '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004',
        '2003', '2002', '2001', '2000', '1987'
    ];

    const coresConhecidas = [
        'Branco', 'Preto', 'Prata', 'Cinza', 'Azul', 'Vermelho', 'Verde', 'Amarelo', 'Laranja', 'Roxo',
        'Marrom', 'Bege', 'Dourado', 'Rosa'
    ];

    const secretariasConhecidas = [
        'Administração', 'Saúde', 'Educação', 'Finanças', 'Obras', 'Transportes', 'Meio Ambiente', 'Cultura', 'Esportes',
        'Assistência Social', 'Desenvolvimento Rural', 'Turismo', 'Segurança Pública', 'Planejamento', 'Agricultura',
        'Comunicação', 'Ciência e Tecnologia', 'Infraestrutura', 'Recursos Humanos', 'Habitação', 'Justiça', 'Trabalho', 'Indústria e Comércio'
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const noticiasRef = ref(database, `db/cidades/${KEY}/frota`);
            const novaNoticiaRef = await push(noticiasRef, {
                marca,
                modelo,
                ano,
                cor,
                secretaria,
                placa,
            });

            navigate(`/${KEY}/veiculos`);

            console.log('Novo ID:', novaNoticiaRef.key);

        } catch (error) {
            console.error('Erro ao enviar', error);
        }
    };

    return (
        <Box p="20px" bgcolor="#1D1D1D" borderRadius="20px" mx="auto" mt="40px" maxWidth="800px" sx={{ width: '90%', maxWidth: '800px', mx: 'auto', mt: '40px' }}>
            <Divider sx={{ color: "#0F71FD", mb: 2, borderRadius: "25px", backgroundColor: "transparent", padding: "20px", fontWeight: 'bold', fontSize: '22px' }} textAlign="left">
                NOVO VEICULO - FROTA
            </Divider>

            <form onSubmit={handleSubmit}>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Placa"
                            value={placa}
                            style={{ color: 'white' }}
                            onChange={(e) => setPlaca(e.target.value)}
                            InputLabelProps={{ style: { color: "#FFF" } }}
                            InputProps={{ style: { color: "#FFF" } }}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel style={{ color: 'white' }}>Marca</InputLabel>
                            <Select
                                style={{ color: 'white' }}
                                value={marca}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                                InputProps={{ style: { color: "#FFF" } }}
                                onChange={(e) => setMarca(e.target.value)}
                                label="Marca"
                            >
                                {marcasConhecidas.map((marca, index) => (
                                    <MenuItem key={index} value={marca}>{marca}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel style={{ color: 'white' }}>Ano</InputLabel>
                            <Select
                                style={{ color: 'white' }}

                                value={ano}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                                InputProps={{ style: { color: "#FFF" } }}
                                onChange={(e) => setAno(e.target.value)}
                                label="Anos"
                            >
                                {anosConhecidos.map((anos, index) => (

                                    <MenuItem key={index} value={anos}>{anos}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel style={{ color: 'white' }}>Cores</InputLabel>
                            <Select
                                style={{ color: 'white' }}

                                value={cor}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                                InputProps={{ style: { color: "#FFF" } }}
                                onChange={(e) => setCor(e.target.value)}
                                label="Cores"
                            >
                                {coresConhecidas.map((cores, index) => (

                                    <MenuItem key={index} value={cores}>{cores}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel style={{ color: 'white' }}>Secretaria</InputLabel>
                            <Select
                                style={{ color: 'white' }}

                                value={secretaria}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                                InputProps={{ style: { color: "#FFF" } }}
                                onChange={(e) => setSecretaria(e.target.value)}
                                label="Secretaria"
                            >
                                {secretariasConhecidas.map((secretaria, index) => (

                                    <MenuItem key={index} value={secretaria}>{secretaria}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel style={{ color: 'white' }}>Modelos</InputLabel>
                            <Select
                                style={{ color: 'white' }}

                                value={modelo}
                                InputLabelProps={{ style: { color: "#FFF" } }}
                                InputProps={{ style: { color: "#FFF" } }}
                                onChange={(e) => setModelo(e.target.value)}
                                label="Modelos"
                            >
                                {modelosConhecidos.map((modelos, index) => (

                                    <MenuItem key={index} value={modelos}>{modelos}</MenuItem>
                                ))}


                            </Select>
                        </FormControl>
                    </Grid>




                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default NovoVeiculo;
