import { useState, useEffect, useRef } from "react";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, get, child, getDatabase, onValue } from 'firebase/database';
import { useNavigate } from "react-router-dom";
import { checkToken, checkTokenValidity, generateAndSaveToken, generateToken, reloadPage } from "../services/script";
import firebaseApp from "../integration/FirebaseConfig";

const AuthProvider = () => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [cidade, setCidade] = useState(null);
    const [acesso, setAcesso] = useState(false);
    const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
    const auth = getAuth(firebaseApp);
    const database = getDatabase(firebaseApp);
    const dbRefAtivo = ref(database, `/config/manutencao/ativo`);

    // CARGOS
    const [admin, setAdmin] = useState(false);
    const [licitacao, setLicitacao] = useState(false);
    const [RH, setRH] = useState(false);
    const [OperadorAdmin, setOperadorAdmin] = useState(false);
    const [Operador, setOperador] = useState(false);
    const [MidiaMarketing, setMidiaMarketing] = useState(false);
    const [SalaEmpreendedor, setSalaEmpreendedor] = useState(false);


    const navigate = useNavigate();



    const handleSidebarToggle = (isCollapsed) => {
        setIsSidebarExpanded(!isCollapsed);
    };

    useEffect(() => {
        const dbRef = ref(database, `/usuarios`);

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsAuthenticated(true);
                const userId = user.uid;
                try {
                    const snapshot = await get(child(dbRef, userId));
                    if (snapshot.exists()) {
                        const user = snapshot.val();


                        if (user.ativo === true) {

                            setCidade(user.cidade);

                            if (user.acesso === false) {
                                setAcesso(false);
                            } else {
                                setAcesso(true);
                            }

                            if (user.categoria === "Admin") {
                                setAdmin(true);
                            } else if (user.categoria === "Licitação") {
                                setLicitacao(true);
                            } else if (user.categoria === "RH") {
                                setRH(true)
                            } else if (user.categoria === "OperadorAdmin") {
                                setOperadorAdmin(true)
                            } else if (user.categoria === "Operador") {
                                setOperador(true)
                            } else if (user.categoria === "Midia/Marketing") {
                                setMidiaMarketing(true)
                            } else if (user.categoria === "Sala do Empreendedor") {
                                setSalaEmpreendedor(true)
                            } else {
                                setIsAuthenticated(false);
                            }

                            setIsAuthenticated(true);

                            setTimeout(async () => {
                                await checkToken(database, auth, userId)
                            }, 1000);


                        } else {
                            setTimeout(async () => {
                                await checkToken(database, auth, userId)
                            }, 1000);
                        }



                    } else {
                        /* console.log('ID do usuário não encontrado no banco de dados'); */
                    }
                } catch (error) {
                    console.error('Erro ao verificar status do usuário:', error);
                }




            } else {
                /*                 console.log("Not Authenticated");
                                console.log("Token no generate"); */
                setIsAuthenticated(false);
            }

            setTimeout(() => {
                setLoading(false);
            }, 300);

        });

    }, [auth, database, dbRefAtivo, navigate]);


    return {
        isAuthenticated,
        admin,
        licitacao,
        RH,
        OperadorAdmin,
        Operador,
        MidiaMarketing,
        SalaEmpreendedor,
        acesso,
        manutencaoAtiva,
        cidade,
        loading,
        isSidebarExpanded,
        handleSidebarToggle
    };

}

export default AuthProvider;