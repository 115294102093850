import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
  Popover
} from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";
import FilterIcon from "@mui/icons-material/FilterList";


const HeaderCargos = ({ title, subtitle, onFilterApply }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({

    nome: "",
    email: "",
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event, filterType) => {
    const value = event.target.value;
    setFilterValues((prevValues) => ({
      ...prevValues,
      [filterType]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      data: date,
    }));
  };

  const handleFilterApply = () => {
    onFilterApply(filterValues);
    handleMenuClose();
  };

  const handleFilterReset = () => {
    setFilterValues({

      nome: "",
      email: "",

    });
    onFilterApply({

      nome: "",
      email: "",
    });
    handleMenuClose();
  };

  return (
    <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >

      <img src="/images/bs.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        mb="5px"
        flexGrow={1}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>

      <IconButton
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #0F71FD",
          borderRadius: "4px",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          color: "white",
        }}
        onClick={handleMenuOpen}
      >
        <FilterIcon />
        <Typography variant="button" ml={1}>
          FILTRAR
        </Typography>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="Nome"
              value={filterValues.nome}
              onChange={(event) => handleFilterChange(event, "nome")}
            />
          </MenuItem>

          <MenuItem>
            <TextField sx={{ minWidth: 300 }}
              label="E-mail"
              value={filterValues.email}
              onChange={(event) => handleFilterChange(event, "email")}
            />
          </MenuItem>


          <MenuItem>
            <Button
              style={{ backgroundColor: "#0F71FD", fontWeight: "bold", marginRight: "10px" }}
              onClick={handleFilterApply}
            >
              Filtrar
            </Button>
            <Button
              style={{ backgroundColor: "white", fontWeight: "bold" }}
              onClick={handleFilterReset}
            >
              Voltar
            </Button>
          </MenuItem>
        </Box>
      </Popover>

    </Box>
  );
};

export default HeaderCargos;