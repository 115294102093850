import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getDatabase, ref, onValue, remove } from '@firebase/database';
import firebaseApp, { KEY, KEY_CATEGORIA } from '../../integration/FirebaseConfig';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Helmet } from "react-helmet";
import AddBoxIcon from '@mui/icons-material/AddBox';

const database = getDatabase(firebaseApp);

const ESIC = () => {
  const [userData, setUserData] = useState([]);
  const [documentType, setDocumentType] = useState('esic');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery("(max-width: 700px)");
  const navigate = useNavigate();

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const columns = [

    {
      field: "email",
      headerName: "E-mail",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requestType",
      headerName: "Requisição",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Data",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      filterable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>


        </Box>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "requestType",
      headerName: "Requisição",
      flex: 1,
      cellClassName: "name-column--cell",
      fontWeight: 'bold',
      filterable: true,
      valueFormatter: (params) => params.value.toUpperCase(),
    },

    {
      field: "edit",
      headerName: "Ações",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => handleAdd(row)}
            sx={{
              backgroundColor: '#0F71FD',
              borderRadius: '4px',
              border: "1px solid #1D1D1D",
              padding: '8px',
              marginLeft: 1
            }}
          >
            <EditOutlinedIcon />
          </IconButton>

        </Box>
      ),
    },
  ];

  useEffect(() => {
    try {


      const usersRef = ref(database, `db/cidades/${KEY}/${documentType}`);

      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {

          const dados = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));


          dados.sort((a, b) => a.timestamp - b.timestamp);


          dados.reverse();


          setUserData(dados);
        } else {

          setUserData([]);
        }
      });
    } catch (error) {
      console.error('Erro ao obter dados do Firebase:', error);
    }
  }, [documentType]);


  const handleAdd = (user) => {
    navigate(`/${KEY}/e-sic/editar`, { state: { userData: user, documentType } });
  };
  



  return (
    <>

      <Helmet>
        <title>Painel - Documentos</title>
      </Helmet>

      <Box m="20px">
        <Box mb="30px" display="flex" alignItems="center" backgroundColor="#1d1d1d" padding={2} borderRadius={3} >
          <img src="/images/bs.png" style={{ maxWidth: 32, opacity: 1, marginBottom: 5, marginRight: 10 }} />
          {!isMobile ? (<> <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            E-SIC
          </Typography></>) : (<> <Typography
            variant="h4"
            color={colors.grey[100]}
            fontWeight="bold"
            mb="5px"
            flexGrow={1}
          >
            E-SIC
          </Typography></>)}





        </Box>

        <Box display="flex" alignItems="center" backgroundColor="#1d1d1d">

          <FormControl variant="filled" fullWidth>
            <InputLabel htmlFor="document-type-select" style={{ color: 'white' }}>Tipo de Documento</InputLabel>

            {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_ADMIN_USER || KEY_CATEGORIA === process.env.REACT_APP_KEY_OPERADOR_USER ? (<>

              <Select
                value={documentType}
                onChange={handleDocumentTypeChange}
                inputProps={{
                  id: 'document-type-select',
                }}
                style={{ color: colors.grey[100] }}
              >

                <MenuItem value="esic">E-SIC</MenuItem>
                <MenuItem value="cartadeservico">Carta de serviço</MenuItem>
                <MenuItem value="ouvidoria">Ouvidoria</MenuItem>


              </Select>
            </>) : (<>
              <Select
                value={documentType}
                onChange={handleDocumentTypeChange}
                inputProps={{
                  id: 'document-type-select',
                }}
                style={{ color: colors.grey[100] }}
              >

                <MenuItem value="esic">E-SIC</MenuItem>
                <MenuItem value="cartadeservico">Carta de serviço</MenuItem>
                <MenuItem value="ouvidoria">Ouvidoria</MenuItem>


              </Select>
            </>)}


          </FormControl>

        </Box>

        {!isMobile ? (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columns}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        ) : (
          <Box className="watermark-container"
            m="30px 0 0"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.primary[500]}`,
              },
              "& .name-column--cell": {
                color: colors.grey[100],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#1D1D1D',
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#212121",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              ".MuiDataGrid-columnSeparator": {
                color: '#707070 !important'
              }
            }}
          >
            <DataGrid
              rows={userData}
              columns={columnsMobile}
              rowHeight={80}
              filterMode="server"
              hideFooter={true}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )}
      </Box >

    
    </>
  );
};

export default ESIC;
