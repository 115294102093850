import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../theme";

import firebaseApp, { DATA_LOGIN, KEY, KEY_CATEGORIA, KEY_LICENSE, KEY_MASTER, KEY_NOME, NAME } from "../integration/FirebaseConfig";
import {
  HomeOutlined, PeopleOutlined, CalendarTodayOutlined,
  Menu as MenuIcon, AddToPhotos, Comment, Support, Diversity3,
  Apartment, Assignment, ExitToApp, PeopleAlt,
  NotificationsActive, FilterList, InsertChart,
  MenuOpen, Settings, Newspaper, Gavel,
  Image, Work, BeachAccess, SupervisedUserCircle,
  VerifiedUser, ArrowBackIosNew, Engineering
} from "@mui/icons-material";
import {
  Box, Typography, IconButton, useTheme, Avatar, Skeleton, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Button, Select, InputLabel, FormControl, ListItem, RadioGroup,
  FormControlLabel,
  Radio, List, ListItemText, ListItemAvatar, ListItemIcon,
  Fab,
  Collapse,
  ListItemButton
} from "@mui/material";
import '../index.css'
import Switch from '@mui/material/Switch';
import WorkIcon from '@mui/icons-material/Work';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { getDatabase, off, onValue, ref, update } from "firebase/database";
import KeyIcon from '@mui/icons-material/Key';
import GavelIcon from '@mui/icons-material/Gavel';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BuildIcon from '@mui/icons-material/Build';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getAuth } from 'firebase/auth';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import MenuRouter from "./MenuRouter";

const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      icon={icon}
      onClick={() => {
        setSelected(title);
        setIsCollapsed(true);
      }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMobile = ({ onToggle }) => {
  const database = getDatabase(firebaseApp);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [manutencaoAtiva, setManutencaoAtiva] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [showLicense, setShowLicense] = useState(false);
  const [open, setOpen] = React.useState(false);

  const keyCategoriaString = KEY.toString();

  const handleClick = () => {
    setOpen(!open);
  };

  const auth = getAuth();

  const handleToggleLicense = () => {
    setShowLicense(prevShowLicense => !prevShowLicense);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    onToggle(isCollapsed);
  }, [isCollapsed, onToggle]);

  const handleToggle = () => {
    setIsCollapsed(prev => !prev);
  };


  useEffect(() => {
    const manutencaoRef = ref(database, `db/cidades/${KEY}/manutencao/ativo`);

    const onManutencaoValue = (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        console.log(data);
        setManutencaoAtiva(data);
      }
    };

    onValue(manutencaoRef, onManutencaoValue);

    return () => {
      off(manutencaoRef, onManutencaoValue);
    };
  }, []);

  const handleManutencaoChange = (event) => {
    const newValue = event.target.checked;

    update(ref(database, `db/cidades/${KEY}/manutencao`), {
      ativo: newValue
    }).then(() => {
      console.log("Manutenção atualizada com sucesso");
    }).catch((error) => {
      console.error('Erro ao atualizar a manutenção:', error);
    });
  };

  useEffect(() => {
    const database = getDatabase();
    const cidadesRef = ref(database, 'codigo_cidade');

    onValue(cidadesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const citiesArray = Object.entries(data)
          .map(([key, value]) => ({
            id: key,
            codigo: value.codigo,
            nome: value.nome,
            ativo: value.ativo,
            bloqueado: value.bloqueado,
            site: value.site
          }))
          .filter(city => city.ativo === true);
        setCidades(citiesArray);
      }
    });
  }, []);

  const handleConfirmCityChange = () => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const selectedCityData = cidades.find(city => String(city.codigo) === String(selectedCity));

    if (selectedCity == KEY) {
      handleDialogClose();
      return;
    }

    console.log('UserID:', userId);
    console.log('Selected City:', selectedCity);
    console.log('Selected City Data:', selectedCityData);

    if (userId && selectedCityData) {
      const cityCode = Number(selectedCityData.codigo);
      if (!isNaN(cityCode)) {
        update(ref(database, `usuarios/${userId}`), {
          cidade: cityCode,
          site: selectedCityData.site,
          KEY_NAME: selectedCityData.nome
        })
          .then(() => {
            console.log("Cidade e site atualizados com sucesso");
            handleDialogClose();
            window.location.reload();
          })
          .catch((error) => {
            console.error("Erro ao atualizar a cidade e site:", error);
          });
      } else {
        console.error('City code is not a valid number:', selectedCityData.codigo);
      }
    } else {
      console.log('No city data found or user not authenticated');
    }
  };

  return (
    <>
      {isCollapsed && (
        <Fab
          color="primary"
          aria-label="menu"
          onClick={handleToggle}
          sx={{ position: 'fixed', top: 16, left: 16, zIndex: 1300 }}
        >
          <MenuIcon />
        </Fab>
      )}
      <Box
        position="fixed"
        top="0"
        left="0"
        zIndex="1200"
        height="100%"
        width={isCollapsed ? "0" : "75%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s ease",
          backgroundColor: isCollapsed ? "transparent" : "background.paper",
          overflow: "hidden"
        }}
      >
        {!isCollapsed && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 2, position: 'fixed', width: 'inherit', backgroundColor: 'background.paper', zIndex: 1201 }}>

            <img
              src="/images/logobs.png"
              alt="logo"
              onClick={handleToggle}
              style={{
                width: "140px",
              }}
            />
            <IconButton onClick={handleToggle}>
              <ArrowBackIosNew />
            </IconButton>
          </Box>
        )}

        <Box sx={{ flex: 1, marginTop: !isCollapsed ? '72px' : '0', overflowY: "auto", overflowX: "hidden" }}>
          <ProSidebar collapsed={isCollapsed}>
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'transparent' }}>

              <ListItem button onClick={handleToggleLicense}>
                <ListItemAvatar>
                  <Avatar>
                    <CodeOffIcon />
                  </Avatar>
                </ListItemAvatar>
                {showLicense ? (
                  <>
                    {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER ? (<>
                      <ListItemText primary="LICENÇA PRO" secondary={`${KEY_LICENSE.toLocaleUpperCase()}`} />
                    </>) : (<>
                      <ListItemText primary="LICENÇA BASIC" secondary={`${KEY_LICENSE.toLocaleUpperCase()}`} />

                    </>)}
                    <IconButton>
                      <VisibilityOffIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER ? (<>

                      <img
                        src="/images/license_pro.png"
                        alt={`${KEY_LICENSE.toLocaleUpperCase()}`}
                        style={{
                          width: "112px",
                        }}
                      />
                    </>) : (<>
                      <img
                        src="/images/license_basic.png"
                        alt={`${KEY_LICENSE.toLocaleUpperCase()}`}
                        style={{
                          width: "112px",
                        }}
                      />

                    </>)}

                  </>
                )}
              </ListItem>

              <ListItem button onClick={handleClick}>
                <ListItemAvatar>
                  <Avatar>
                    <DeveloperModeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="OPERACIONAL" secondary='INFORMAÇÕES' sx={{ color: 'white' }} />
                {open ? <ExpandLess sx={{ fontSize: '25px' }} /> : <ExpandMore sx={{ fontSize: '25px' }} />}
              </ListItem>



              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <SupervisedUserCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${NAME.toLocaleUpperCase()}`} secondary={`${DATA_LOGIN.toLocaleUpperCase()}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <WorkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary='CIDADE DE ACESSO' secondary={`${KEY_NOME.toLocaleUpperCase()}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <VerifiedUserIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="AUTH ACCESS" secondary={`${KEY_CATEGORIA.toLocaleUpperCase()}`} />
                  </ListItem>

                  {KEY_CATEGORIA === process.env.REACT_APP_KEY_ADMIN_USER && (
                    <>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <EngineeringIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="MANUTENÇÃO" secondary='ATIVAR AVISO ' />
                        <Switch color="success" onChange={handleManutencaoChange} checked={manutencaoAtiva} />
                      </ListItem>

                      {KEY_MASTER === true && (<>
                        <ListItem button onClick={handleDialogOpen}>
                          <ListItemAvatar>
                            <Avatar>
                              <KeyIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary='TROCAR CIDADES' secondary='ALT. CHAVE DE ACESSO' />
                        </ListItem>

                      </>)}


                    </>
                  )}
                </List>
              </Collapse>




            </List>

            <Menu iconShape="square">
              <Typography variant="h6" color={colors.grey[300]} m="15px 0px 5px 20px">
                <Typography>Menu Principal</Typography>
              </Typography>
              <MenuRouter selected={selected} isCollapseds={isCollapsed} collapseSidebar={setIsCollapsed} />

            </Menu>
          </ProSidebar>
        </Box>
      </Box>




      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Selecione um Acesso</DialogTitle>
        <DialogContent>
          <RadioGroup value={selectedCity} color="success" onChange={handleCityChange} >
            {cidades.map((cidade) => (
              <FormControlLabel
                key={cidade.codigo}
                value={cidade.codigo}
                control={<Radio sx={{
                  color: '#0F71FD',
                  '&.Mui-checked': {
                    color: '#0F71FD',
                  },
                }} />}
                label={`${cidade.nome} - ${cidade.codigo}`}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{
            backgroundColor: "transparent",
            border: "1px solid #0F71FD",
            borderRadius: "4px",
            padding: "8px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmCityChange} style={{ backgroundColor: "#0F71FD", fontWeight: "bold", marginRight: "10px" }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarMobile;
